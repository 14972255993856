import ReactDOM from 'react-dom'

function AddPostsToHtml(posts) {
  console.log(posts)
  console.log("Hello")

  let post_elements = []
  for (const post of posts) {
    const element = <div>
        <h1>User {post.username}: </h1>
        <h1>{post.title}<br></br></h1>
        <h>{post.content}<br></br><br></br></h>
        </div>;
    post_elements.push(element)
  }

  ReactDOM.render(<div>{post_elements}</div>, document.getElementById('root'));
}

function GetPosts() {
    return fetch("https://worker.pfalcato.workers.dev/posts").then(response => {
      try {
        return response.json()
      } catch (error) {
        console.log(error.message)
      }}).then(posts => AddPostsToHtml(posts))

}

function App() {
  GetPosts()

  return null
}

export default App;
